<template>
  <section id="social-media">
    <div id="footerMenu" class="container-fluid">
      <div class="row row-cols-md-8">
        <div class="col">
          <ul>
            <li>
              <h4>BAŞKAN</h4>
            </li>
            <li><router-link to="/ozgecmis">Başkan Özgeçmişi</router-link></li>
            <li><router-link to="/baskangaleri">Başkan Galeri</router-link></li>
            <li><router-link to="/mesaj">Başkan'a Mesaj</router-link></li>
            <li>
              <router-link to="/eskibaskanlar">Önceki Başkanlar</router-link>
            </li>
          </ul>
        </div>
        <div class="col">
          <ul>
            <li>
              <h4>HIZLI MENÜ</h4>
            </li>
            <li><router-link to="/duyuruilanlar">Duyurular</router-link></li>
            <li><router-link to="/belgeler">Belge Örnekleri</router-link></li>
            <li><router-link to="/sehitlerimiz">Şehitlerimiz</router-link></li>
            <li><router-link to="/kentrehberi">Kent Rehberi</router-link></li>
            <li><router-link to="/birimler">Birimler</router-link></li>
            <li><router-link to="/muhtarlar">Muhtarlar</router-link></li>
            <li>
              <router-link to="/meclisgundemi">Meclis Gündemi</router-link>
            </li>
            <li><router-link to="/vefatlar">Vefat Edenler</router-link></li>
            <li>
              <router-link to="/raporlar">Faaliyet Raporları</router-link>
            </li>
          </ul>
        </div>
        <div class="col">
          <ul>
            <li><router-link to="/">Anasayfa</router-link></li>
            <li><router-link to="/haberler">Haberler</router-link></li>
            <li><router-link to="/projeler">Projeler</router-link></li>
            <li><router-link to="/tarihce">Tarihçe</router-link></li>
            <li><router-link to="/galeri">Galeri</router-link></li>
            <li><router-link to="/iletisim">İletişim</router-link></li>
          </ul>
        </div>
        <div class="col">
          <ul>
            <li>
              <h4>BİZE ULAŞIN</h4>
            </li>
            <li>Telefon: {{ Belediyebilgiler.iletisim }}</li>
            <li>Fax: {{ Belediyebilgiler.faxnumarasi }}</li>
            <li>
              Özel Kalem Müdürlüğü <br />
              Fax : 0266 576 13 31
            </li>
            <li>
              Adres: {{ Belediyebilgiler.belediyeadresi }}
              <br />
              E-Mail: {{ Belediyebilgiler.mail }}
            </li>
          </ul>
        </div>
        <div class="col">
          <ul>
            <li>
              <a href="#">
                <svg
                  enable-background="new 0 0 24 24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z"
                  />
                </svg>
              </a>
            </li>
            <li class="mt-5">
              <a href="#">
                <svg
                  id="Bold"
                  enable-background="new 0 0 24 24"
                  height="512"
                  viewBox="0 0 24 24"
                  width="512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m21.534 7.113c.976-.693 1.797-1.558 2.466-2.554v-.001c-.893.391-1.843.651-2.835.777 1.02-.609 1.799-1.566 2.165-2.719-.951.567-2.001.967-3.12 1.191-.903-.962-2.19-1.557-3.594-1.557-2.724 0-4.917 2.211-4.917 4.921 0 .39.033.765.114 1.122-4.09-.2-7.71-2.16-10.142-5.147-.424.737-.674 1.58-.674 2.487 0 1.704.877 3.214 2.186 4.089-.791-.015-1.566-.245-2.223-.606v.054c0 2.391 1.705 4.377 3.942 4.835-.401.11-.837.162-1.29.162-.315 0-.633-.018-.931-.084.637 1.948 2.447 3.381 4.597 3.428-1.674 1.309-3.8 2.098-6.101 2.098-.403 0-.79-.018-1.177-.067 2.18 1.405 4.762 2.208 7.548 2.208 8.683 0 14.342-7.244 13.986-14.637z"
                  />
                </svg>
              </a>
            </li>
            <li class="mt-5">
              <a href="#">
                <svg
                  enable-background="new 0 0 512 512"
                  version="1.1"
                  viewBox="0 0 512 512"
                  xml:space="preserve"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m256 152c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm0 0c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm181-152h-362c-41.4 0-75 33.6-75 75v362c0 41.4 33.6 75 75 75h362c41.4 0 75-33.6 75-75v-362c0-41.4-33.6-75-75-75zm-181 392c-74.399 0-135-60.601-135-135 0-74.401 60.601-135 135-135s135 60.599 135 135c0 74.399-60.601 135-135 135zm165-270c-16.5 0-30-13.5-30-30s13.5-30 30-30 30 13.5 30 30-13.5 30-30 30zm-165 30c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm0 0c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm0 0c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105z"
                  />
                </svg>
              </a>
            </li>
            <li class="mt-5">
              <a href="#">
                <svg
                  height="682pt"
                  viewBox="-21 -117 682.66672 682"
                  width="682pt"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m626.8125 64.035156c-7.375-27.417968-28.992188-49.03125-56.40625-56.414062-50.082031-13.703125-250.414062-13.703125-250.414062-13.703125s-200.324219 0-250.40625 13.183593c-26.886719 7.375-49.03125 29.519532-56.40625 56.933594-13.179688 50.078125-13.179688 153.933594-13.179688 153.933594s0 104.378906 13.179688 153.933594c7.382812 27.414062 28.992187 49.027344 56.410156 56.410156 50.605468 13.707031 250.410156 13.707031 250.410156 13.707031s200.324219 0 250.40625-13.183593c27.417969-7.378907 49.03125-28.992188 56.414062-56.40625 13.175782-50.082032 13.175782-153.933594 13.175782-153.933594s.527344-104.382813-13.183594-154.460938zm-370.601562 249.878906v-191.890624l166.585937 95.945312zm0 0"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>
        <div class="col">
          <img src="assets/images/logosocial.png" alt="" />
          <div class="triangle"></div>
        </div>
      </div>
      <div class="triangle"></div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      Belediyebilgiler: {},
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
  },
};
</script>
<style>
#footerMenu ul li a {
  color: #fff;
  text-decoration: none;
}
#footerMenu ul li a:hover {
  color: #fff !important;
  border-bottom: 1px solid #fff;

  border-left: 15px solid #fff;

  padding-left: 10px;
  transition: all 0.4s ease;
}
</style>