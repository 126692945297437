<template>
  <header id="header">
    <div class="container">
      <div class="row">
        <div class="col-lg-2 logo-show">
          <router-link to="/">
            <img :src="Belediyebilgiler.kapak" alt="" />
          </router-link>
        </div>
        <div class="col-lg textarea">
          <div class="show-air">
            <div class="air mt-5">
              <p>
                Hava Durumu
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xmlns:svgjs="http://svgjs.com/svgjs"
                  version="1.1"
                  width="512"
                  height="512"
                  x="0"
                  y="0"
                  viewBox="0 0 504.5 504.5"
                  style="enable-background: new 0 0 512 512"
                  xml:space="preserve"
                  class=""
                >
                  <g>
                    <g xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="m409.887 289.236c13.139-18.8 20.846-41.676 20.846-66.353 0-64.047-51.92-115.967-115.967-115.967-52.755 0-97.282 35.227-111.343 83.441v132.209h210.077z"
                        fill="#217c3d"
                        data-original="#ffd900"
                        class=""
                      />
                      <path
                        d="m430.733 222.883c0-64.047-51.92-115.967-115.967-115.967-3.849 0-7.648.21-11.401.576 58.6 5.82 104.367 55.259 104.367 115.391 0 24.676-7.707 47.553-20.846 66.353l3.613 33.33h23l-3.613-33.33c13.14-18.8 20.847-41.677 20.847-66.353z"
                        fill="#217c3d"
                        data-original="#ffbb00"
                        class=""
                      />
                      <g>
                        <path
                          d="m497 230.383h-33.133c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h33.133c4.142 0 7.5 3.357 7.5 7.5 0 4.142-3.358 7.5-7.5 7.5z"
                          fill="#217c3d"
                          data-original="#ffd900"
                          class=""
                        />
                      </g>
                      <g>
                        <path
                          d="m420.203 124.938c-1.919 0-3.839-.732-5.303-2.196-2.929-2.929-2.929-7.678 0-10.606l23.429-23.43c2.929-2.93 7.678-2.929 10.607-.001 2.929 2.929 2.929 7.678 0 10.606l-23.429 23.43c-1.465 1.464-3.384 2.197-5.304 2.197z"
                          fill="#217c3d"
                          data-original="#ffd900"
                          class=""
                        />
                      </g>
                      <g>
                        <path
                          d="m314.767 81.25c-4.142 0-7.5-3.357-7.5-7.5v-33.133c0-4.143 3.358-7.5 7.5-7.5s7.5 3.357 7.5 7.5v33.133c0 4.143-3.358 7.5-7.5 7.5z"
                          fill="#217c3d"
                          data-original="#ffd900"
                          class=""
                        />
                      </g>
                      <g>
                        <path
                          d="m209.321 124.914c-1.919 0-3.839-.732-5.303-2.196l-23.429-23.429c-2.929-2.93-2.929-7.678 0-10.607 2.929-2.928 7.678-2.928 10.606 0l23.429 23.429c2.929 2.93 2.929 7.678 0 10.607-1.464 1.464-3.384 2.196-5.303 2.196z"
                          fill="#217c3d"
                          data-original="#ffd900"
                          class=""
                        />
                      </g>
                      <g>
                        <path
                          d="m257.699 92.605c-2.943 0-5.736-1.743-6.932-4.632l-6.34-15.306c-1.585-3.827.232-8.214 4.059-9.799 3.826-1.585 8.215.231 9.799 4.059l6.34 15.306c1.585 3.827-.232 8.214-4.059 9.799-.939.388-1.911.573-2.867.573z"
                          fill="#217c3d"
                          data-original="#ffa400"
                          class=""
                        />
                      </g>
                      <g>
                        <path
                          d="m176.983 173.314c-.956 0-1.929-.184-2.867-.573l-15.306-6.34c-3.827-1.585-5.644-5.972-4.059-9.799 1.585-3.826 5.973-5.648 9.799-4.059l15.306 6.34c3.827 1.585 5.644 5.972 4.059 9.799-1.196 2.888-3.989 4.632-6.932 4.632z"
                          fill="#217c3d"
                          data-original="#ffa400"
                          class=""
                        />
                      </g>
                      <g>
                        <path
                          d="m467.85 293.795c-.957 0-1.929-.184-2.867-.573l-15.306-6.34c-3.827-1.585-5.644-5.972-4.059-9.799 1.585-3.826 5.974-5.647 9.799-4.059l15.306 6.34c3.827 1.585 5.644 5.972 4.059 9.799-1.196 2.888-3.989 4.632-6.932 4.632z"
                          fill="#217c3d"
                          data-original="#ffa400"
                          class=""
                        />
                      </g>
                      <g>
                        <path
                          d="m452.55 173.314c-2.943 0-5.736-1.743-6.932-4.632-1.585-3.827.232-8.214 4.059-9.799l15.306-6.34c3.827-1.585 8.214.232 9.799 4.059s-.232 8.214-4.059 9.799l-15.306 6.34c-.938.389-1.911.573-2.867.573z"
                          fill="#217c3d"
                          data-original="#ffa400"
                          class=""
                        />
                      </g>
                      <g>
                        <path
                          d="m371.834 92.605c-.957 0-1.929-.184-2.867-.573-3.827-1.585-5.644-5.972-4.059-9.799l6.34-15.306c1.585-3.826 5.973-5.647 9.799-4.059 3.827 1.585 5.644 5.972 4.059 9.799l-6.34 15.306c-1.196 2.887-3.989 4.632-6.932 4.632z"
                          fill="#217c3d"
                          data-original="#ffa400"
                          class=""
                        />
                      </g>
                      <g>
                        <path
                          d="m404.739 289.149h-69.507c-2.9 0-5.362-2.08-5.897-4.93-10.155-54.171-57.967-95.069-115.225-94.463-63.743.675-114.71 53.37-114.71 117.118v9.409c0 3.314-2.686 6-6 6h-17.914c-41.106 0-75.179 32.879-75.484 73.984-.307 41.432 33.187 75.116 74.548 75.116h331.333c50.552 0 91.49-41.168 91.114-91.807-.372-50.241-42.016-90.427-92.258-90.427z"
                          fill="#217c3d"
                          data-original="#c8effe"
                          class=""
                        />
                        <g fill="#99e2fc">
                          <g>
                            <path
                              d="m404.739 289.149h-23c50.242 0 91.885 40.186 92.258 90.426.375 50.639-40.562 91.808-91.114 91.808h23c50.553 0 91.49-41.168 91.114-91.808-.372-50.24-42.016-90.426-92.258-90.426z"
                              fill="#000000"
                              data-original="#000000"
                              class=""
                            />
                            <path
                              d="m306.336 284.219c.534 2.851 2.996 4.931 5.897 4.931h23c-2.9 0-5.362-2.08-5.897-4.931-10.155-54.171-57.967-95.069-115.225-94.463-3.437.036-6.835.233-10.192.566 51.461 5.09 93.029 43.82 102.417 93.897z"
                              fill="#000000"
                              data-original="#000000"
                              class=""
                            />
                          </g>
                          <path
                            d="m149.1 322.283c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5h-49.7v8.999c0 3.314-2.686 6-6 6h-17.914c-.007 0-.013.001-.02.001z"
                            fill="#217c3d"
                            data-original="#99e2fc"
                            class=""
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                22°
              </p>
            </div>
            <div class="phone">
              <p>
                <svg
                  enable-background="new 0 0 405.333 405.333"
                  version="1.1"
                  viewBox="0 0 405.33 405.33"
                  xml:space="preserve"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m373.33 266.88c-25.003 0-49.493-3.904-72.704-11.563-11.328-3.904-24.192-0.896-31.637 6.699l-46.016 34.752c-52.8-28.181-86.592-61.952-114.39-114.37l33.813-44.928c8.512-8.512 11.563-20.971 7.915-32.64-7.723-23.36-11.648-47.872-11.648-72.832 0-17.643-14.357-32-32-32h-74.667c-17.643 0-32 14.357-32 32 0 205.84 167.49 373.33 373.33 373.33 17.643 0 32-14.357 32-32v-74.453c0-17.643-14.357-32-32-32z"
                  />
                </svg>
                {{ Belediyebilgiler.iletisim }}
              </p>
              <div class="mail">
                <p>
                  <svg
                    enable-background="new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    xml:space="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m467 76h-422c-24.863 0-45 20.262-45 45v270c0 24.885 20.285 45 45 45h422c24.655 0 45-20.03 45-45v-270c0-24.694-20.057-45-45-45zm-6.302 30c-9.194 9.145-167.42 166.53-172.88 171.97-8.5 8.5-19.8 13.18-31.82 13.18s-23.32-4.681-31.848-13.208c-3.674-3.655-160.15-159.3-172.85-171.94h409.4zm-430.7 278.89v-257.77l129.64 128.96-129.64 128.81zm21.321 21.106 129.59-128.76 22.059 21.943c14.166 14.166 33 21.967 53.033 21.967s38.867-7.801 53.005-21.939l22.087-21.971 129.59 128.76h-409.36zm430.68-21.106-129.64-128.81 129.64-128.96v257.77z"
                    />
                  </svg>
                  {{ Belediyebilgiler.mail }}
                </p>
              </div>
            </div>
          </div>

          <div class="show-header">
            <div class="tool">
              <div id="menutoggle">
                <input type="checkbox" />
                <span></span>
                <span></span>
                <span></span>
                <ul id="menu">
                  <a href="#">
                    <li>ANASAYFA</li>
                  </a>
                  <a href="#">
                    <li>
                      <div class="dropdown">
                        <button
                          class="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          BAŞKAN
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuOffset"
                        >
                          <li>
                            <a class="dropdown-item" href="#"
                              >Başkanın Özgeçmişi</a
                            >
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">Başkan Galeri</a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#"
                              >Başkanın Mesajı</a
                            >
                          </li>
                        </ul>
                      </div>
                    </li>
                  </a>
                  <a href="#">
                    <li>
                      <div class="dropdown">
                        <button
                          class="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          KURUMSAL
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuOffset"
                        >
                          <li>
                            <a class="dropdown-item" href="#">Birimler</a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">Meclis Üyeleri</a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">Muhtarlarımız</a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#"
                              >Meclis Kararları</a
                            >
                          </li>
                          <li>
                            <a class="dropdown-item" href="#"
                              >Faaliyet Raporları</a
                            >
                          </li>
                        </ul>
                      </div>
                    </li>
                  </a>
                  <a href="#">
                    <li>
                      <div class="dropdown">
                        <button
                          class="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          GÜNCEL
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuOffset"
                        >
                          <li>
                            <a class="dropdown-item" href="#">Haberler</a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">Projeler</a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">Duyurular</a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </a>
                  <a href="#">
                    <li>
                      <div class="dropdown">
                        <button
                          class="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {{ Belediyebilgiler.belediyeadi }}
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuOffset"
                        >
                          <li>
                            <a class="dropdown-item" href="#">Tarihçe</a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">Kent Rehberi</a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">Vefat Edenler</a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">Galeri</a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">Şehitlerimiz</a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#"
                              >Belge Örnekleri</a
                            >
                          </li>
                        </ul>
                      </div>
                    </li>
                  </a>
                  <a href="#">
                    <li>E-PERSONEL</li>
                  </a>
                  <a href="#">
                    <li>İLETİŞİM</li>
                  </a>
                  <div class="social">
                    <ul>
                      <li>
                        <a href="#">
                          <svg
                            id="Bold"
                            enable-background="new 0 0 24 24"
                            height="512"
                            viewBox="0 0 24 24"
                            width="512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="m21.534 7.113c.976-.693 1.797-1.558 2.466-2.554v-.001c-.893.391-1.843.651-2.835.777 1.02-.609 1.799-1.566 2.165-2.719-.951.567-2.001.967-3.12 1.191-.903-.962-2.19-1.557-3.594-1.557-2.724 0-4.917 2.211-4.917 4.921 0 .39.033.765.114 1.122-4.09-.2-7.71-2.16-10.142-5.147-.424.737-.674 1.58-.674 2.487 0 1.704.877 3.214 2.186 4.089-.791-.015-1.566-.245-2.223-.606v.054c0 2.391 1.705 4.377 3.942 4.835-.401.11-.837.162-1.29.162-.315 0-.633-.018-.931-.084.637 1.948 2.447 3.381 4.597 3.428-1.674 1.309-3.8 2.098-6.101 2.098-.403 0-.79-.018-1.177-.067 2.18 1.405 4.762 2.208 7.548 2.208 8.683 0 14.342-7.244 13.986-14.637z"
                            />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <svg
                            height="682pt"
                            viewBox="-21 -117 682.66672 682"
                            width="682pt"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="m626.8125 64.035156c-7.375-27.417968-28.992188-49.03125-56.40625-56.414062-50.082031-13.703125-250.414062-13.703125-250.414062-13.703125s-200.324219 0-250.40625 13.183593c-26.886719 7.375-49.03125 29.519532-56.40625 56.933594-13.179688 50.078125-13.179688 153.933594-13.179688 153.933594s0 104.378906 13.179688 153.933594c7.382812 27.414062 28.992187 49.027344 56.410156 56.410156 50.605468 13.707031 250.410156 13.707031 250.410156 13.707031s200.324219 0 250.40625-13.183593c27.417969-7.378907 49.03125-28.992188 56.414062-56.40625 13.175782-50.082032 13.175782-153.933594 13.175782-153.933594s.527344-104.382813-13.183594-154.460938zm-370.601562 249.878906v-191.890624l166.585937 95.945312zm0 0"
                            />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <svg
                            id="Bold"
                            enable-background="new 0 0 24 24"
                            height="512"
                            viewBox="0 0 24 24"
                            width="512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z"
                            />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <svg
                            enable-background="new 0 0 512 512"
                            version="1.1"
                            viewBox="0 0 512 512"
                            xml:space="preserve"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="m256 152c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm0 0c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm181-152h-362c-41.4 0-75 33.6-75 75v362c0 41.4 33.6 75 75 75h362c41.4 0 75-33.6 75-75v-362c0-41.4-33.6-75-75-75zm-181 392c-74.399 0-135-60.601-135-135 0-74.401 60.601-135 135-135s135 60.599 135 135c0 74.399-60.601 135-135 135zm165-270c-16.5 0-30-13.5-30-30s13.5-30 30-30 30 13.5 30 30-13.5 30-30 30zm-165 30c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm0 0c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm0 0c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105z"
                            />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </ul>
              </div>
            </div>

            <ul>
              <li>
                <router-link to="/"> ANASAYFA </router-link>
              </li>
              <li>
                <div class="dropdown">
                  <button
                    class="btn dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    BAŞKAN
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuOffset"
                  >
                    <li>
                      <router-link class="dropdown-item" to="/ozgecmis"
                        >Başkanın Özgeçmişi</router-link
                      >
                    </li>
                    <li>
                      <router-link class="dropdown-item" to="/baskangaleri"
                        >Başkan Galeri</router-link
                      >
                    </li>
                    <li>
                      <router-link class="dropdown-item" to="/mesaj"
                        >Başkanın Mesajı</router-link
                      >
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <button
                    class="btn dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    KURUMSAL
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuOffset"
                  >
                    <li>
                      <router-link class="dropdown-item" to="/birimler"
                        >Birimler</router-link
                      >
                    </li>
                    <li>
                      <router-link class="dropdown-item" to="/meclisuyeleri"
                        >Meclis Üyeleri</router-link
                      >
                    </li>
                    <li>
                      <router-link class="dropdown-item" to="/muhtarlar"
                        >Muhtarlarımız</router-link
                      >
                    </li>
                    <li>
                      <router-link class="dropdown-item" to="/kararlar"
                        >Meclis Kararları</router-link
                      >
                    </li>
                    <li>
                      <router-link class="dropdown-item" to="/raporlar"
                        >Faaliyet Raporları</router-link
                      >
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <button
                    class="btn dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    GÜNCEL
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuOffset"
                  >
                    <li>
                      <router-link class="dropdown-item" to="/haberler"
                        >Haberler</router-link
                      >
                    </li>
                    <li>
                      <router-link class="dropdown-item" to="/projeler"
                        >Projeler</router-link
                      >
                    </li>
                    <li>
                      <router-link class="dropdown-item" to="/duyuruilanlar"
                        >Duyurular</router-link
                      >
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <button
                    class="btn dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{ Belediyebilgiler.belediyeadi }}
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuOffset"
                  >
                    <li>
                      <router-link class="dropdown-item" to="/tarihce"
                        >Tarihçe</router-link
                      >
                    </li>
                    <li>
                      <router-link class="dropdown-item" to="/kentrehberi"
                        >Kent Rehberi</router-link
                      >
                    </li>
                    <li>
                      <router-link class="dropdown-item" to="/vefat"
                        >Vefat Edenler</router-link
                      >
                    </li>
                    <li>
                      <router-link class="dropdown-item" to="/galeri"
                        >Galeri</router-link
                      >
                    </li>
                    <li>
                      <router-link class="dropdown-item" to="/sehitlerimiz"
                        >Şehitlerimiz</router-link
                      >
                    </li>
                    <li>
                      <router-link class="dropdown-item" to="/belgeler"
                        >Belge Örnekleri</router-link
                      >
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <a href="#"> E-PERSONEL </a>
              </li>
              <li>
                <router-link to="/iletisim"> İLETİŞİM </router-link>
              </li>
            </ul>
          </div>

          <div class="social">
            <ul>
              <li>
                <a :href="Belediyebilgiler.twit">
                  <svg
                    id="Bold"
                    enable-background="new 0 0 24 24"
                    height="512"
                    viewBox="0 0 24 24"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m21.534 7.113c.976-.693 1.797-1.558 2.466-2.554v-.001c-.893.391-1.843.651-2.835.777 1.02-.609 1.799-1.566 2.165-2.719-.951.567-2.001.967-3.12 1.191-.903-.962-2.19-1.557-3.594-1.557-2.724 0-4.917 2.211-4.917 4.921 0 .39.033.765.114 1.122-4.09-.2-7.71-2.16-10.142-5.147-.424.737-.674 1.58-.674 2.487 0 1.704.877 3.214 2.186 4.089-.791-.015-1.566-.245-2.223-.606v.054c0 2.391 1.705 4.377 3.942 4.835-.401.11-.837.162-1.29.162-.315 0-.633-.018-.931-.084.637 1.948 2.447 3.381 4.597 3.428-1.674 1.309-3.8 2.098-6.101 2.098-.403 0-.79-.018-1.177-.067 2.18 1.405 4.762 2.208 7.548 2.208 8.683 0 14.342-7.244 13.986-14.637z"
                    />
                  </svg>
                </a>
              </li>
              <!-- <li>
                <a href="#">
                  <svg
                    height="682pt"
                    viewBox="-21 -117 682.66672 682"
                    width="682pt"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m626.8125 64.035156c-7.375-27.417968-28.992188-49.03125-56.40625-56.414062-50.082031-13.703125-250.414062-13.703125-250.414062-13.703125s-200.324219 0-250.40625 13.183593c-26.886719 7.375-49.03125 29.519532-56.40625 56.933594-13.179688 50.078125-13.179688 153.933594-13.179688 153.933594s0 104.378906 13.179688 153.933594c7.382812 27.414062 28.992187 49.027344 56.410156 56.410156 50.605468 13.707031 250.410156 13.707031 250.410156 13.707031s200.324219 0 250.40625-13.183593c27.417969-7.378907 49.03125-28.992188 56.414062-56.40625 13.175782-50.082032 13.175782-153.933594 13.175782-153.933594s.527344-104.382813-13.183594-154.460938zm-370.601562 249.878906v-191.890624l166.585937 95.945312zm0 0"
                    />
                  </svg>
                </a>
              </li> -->
              <li>
                <a :href="Belediyebilgiler.face">
                  <svg
                    id="Bold"
                    enable-background="new 0 0 24 24"
                    height="512"
                    viewBox="0 0 24 24"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a :href="Belediyebilgiler.insta">
                  <svg
                    enable-background="new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    xml:space="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m256 152c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm0 0c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm181-152h-362c-41.4 0-75 33.6-75 75v362c0 41.4 33.6 75 75 75h362c41.4 0 75-33.6 75-75v-362c0-41.4-33.6-75-75-75zm-181 392c-74.399 0-135-60.601-135-135 0-74.401 60.601-135 135-135s135 60.599 135 135c0 74.399-60.601 135-135 135zm165-270c-16.5 0-30-13.5-30-30s13.5-30 30-30 30 13.5 30 30-13.5 30-30 30zm-165 30c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm0 0c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm0 0c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105z"
                    />
                  </svg>
                </a>
              </li>
            </ul>
            <div class="contact">
              <p>
                bizimle iletişime geçin
                <a
                  target="_blank"
                  :href="'https://wa.me/' + Belediyebilgiler.whatsapp"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xmlns:svgjs="http://svgjs.com/svgjs"
                    version="1.1"
                    width="512"
                    height="512"
                    x="0"
                    y="0"
                    viewBox="0 0 512 512"
                    style="enable-background: new 0 0 512 512"
                    xml:space="preserve"
                    class=""
                  >
                    <g>
                      <path
                        xmlns="http://www.w3.org/2000/svg"
                        d="m435.921875 74.351562c-48.097656-47.917968-112.082031-74.3242182-180.179687-74.351562-67.945313 0-132.03125 26.382812-180.445313 74.289062-48.5 47.988282-75.234375 111.761719-75.296875 179.339844v.078125.046875c.0078125 40.902344 10.753906 82.164063 31.152344 119.828125l-30.453125 138.417969 140.011719-31.847656c35.460937 17.871094 75.027343 27.292968 114.933593 27.308594h.101563c67.933594 0 132.019531-26.386719 180.441406-74.296876 48.542969-48.027343 75.289062-111.71875 75.320312-179.339843.019532-67.144531-26.820312-130.882813-75.585937-179.472657zm-180.179687 393.148438h-.089844c-35.832032-.015625-71.335938-9.011719-102.667969-26.023438l-6.621094-3.59375-93.101562 21.175782 20.222656-91.90625-3.898437-6.722656c-19.382813-33.425782-29.625-70.324219-29.625-106.71875.074218-117.800782 96.863281-213.75 215.773437-213.75 57.445313.023437 111.421875 22.292968 151.984375 62.699218 41.175781 41.03125 63.84375 94.710938 63.824219 151.152344-.046875 117.828125-96.855469 213.6875-215.800781 213.6875zm0 0"
                        fill="#217c3d"
                        data-original="#000000"
                        class=""
                      />
                      <path
                        xmlns="http://www.w3.org/2000/svg"
                        d="m186.152344 141.863281h-11.210938c-3.902344 0-10.238281 1.460938-15.597656 7.292969-5.363281 5.835938-20.476562 19.941406-20.476562 48.628906s20.964843 56.40625 23.886718 60.300782c2.925782 3.890624 40.46875 64.640624 99.929688 88.011718 49.417968 19.421875 59.476562 15.558594 70.199218 14.585938 10.726563-.96875 34.613282-14.101563 39.488282-27.714844s4.875-25.285156 3.414062-27.722656c-1.464844-2.429688-5.367187-3.886719-11.214844-6.800782-5.851562-2.917968-34.523437-17.261718-39.886718-19.210937-5.363282-1.941406-9.261719-2.914063-13.164063 2.925781-3.902343 5.828125-15.390625 19.3125-18.804687 23.203125-3.410156 3.894531-6.824219 4.382813-12.675782 1.464844-5.851562-2.925781-24.5-9.191406-46.847656-29.050781-17.394531-15.457032-29.464844-35.167969-32.878906-41.003906-3.410156-5.832032-.363281-8.988282 2.570312-11.898438 2.628907-2.609375 6.179688-6.179688 9.105469-9.582031 2.921875-3.40625 3.753907-5.835938 5.707031-9.726563 1.949219-3.890625.972657-7.296875-.488281-10.210937-1.464843-2.917969-12.691406-31.75-17.894531-43.28125h.003906c-4.382812-9.710938-8.996094-10.039063-13.164062-10.210938zm0 0"
                        fill="#217c3d"
                        data-original="#000000"
                        class=""
                      />
                    </g>
                  </svg>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      Belediyebilgiler: {},
      domAd: "",
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    this.domAd = domainAd;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
  },
};
</script>

<style>
.dropdown-item.active,
.dropdown-item:active {
  color: #fff !important;
  text-decoration: none;
  background-color: #217c3d;
}
</style>