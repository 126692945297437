<template>
  <div>
    <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div>
    <section class="page-banner">
      

      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Mesaj</h1>
          </div>
        </div>
      </div>
    </section>
    <div class="sidebar-page-container">
      <div class="auto-container">
        <div class="row clearfix">
          <!--Content Side-->
          <div class="content-side col-lg-8 col-md-12 col-sm-12">
            <div class="content-inner">
              <div class="single-post">
                <div class="post-details">
                  <div class="main-image-box">
                    <figure class="image">
                      <img :src="BaskanBilgisi.kapak" style="width:50%; margin-left:25%" alt />
                    </figure>
                  </div>

                  <h2>{{BaskanBilgisi.baskanadi}}</h2>
                  <h4>{{BaskanBilgisi.baskanslogan}}</h4>

                  <p style="white-space: pre-line;" v-html="BaskanBilgisi.mesaj"></p>
                </div>

                <div class="share-post">
                  <strong>Başkan Sosyal Medya Hesapları</strong>

                  <ul class="links clearfix">
                    <li class="facebook">
                      <a :href="BaskanBilgisi.baskanface" target="_blank">
                        <span class="icon fab fa-facebook-f"></span>
                        <span class="txt">Facebook</span>
                      </a>
                    </li>

                    <li class="twitter">
                      <a :href="BaskanBilgisi.baskantwit" target="_blank">
                        <span class="icon fab fa-twitter"></span>
                        <span class="txt">Twiter</span>
                      </a>
                    </li>

                    <li class="linkedin">
                      <a :href="BaskanBilgisi.baskaninsta" target="_blank">
                        <span class="icon fab fa-instagram"></span>
                        <span class="txt">Instagram</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!--Sidebar Side-->

          <Sidebar/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from '@/views/Sidebar'
export default {
  name: "Mesaj",
  data(){
      return{
          BaskanBilgisi:{},
          loadkont:false
      }
  },
  components:{
      Sidebar
  },
  async created(){
     const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Baskan");
    this.BaskanBilgisi = this.$store.getters.getBaskan;
      this.loadkont=true;
  }
};
</script>